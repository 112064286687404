<template lang="">
  <div>
    <b-card v-if="showFilter">
      <h1>Filter</h1>
      <b-row>
        <b-col cols="12">
          <form-v-select
            ref="region"
            v-model="region"
            dataurl="region"
            col-label="2"
            label="Region"
            placeholder="Semua Region"
            :item_text="'name'"
          />
        </b-col>
        <b-col cols="12">
          <b-form-group
            label-cols-md="2"
            label="Tanggal"
          >
            <b-input-group>
              <flat-pickr
                v-model="date_start"
                class="form-control"
                placeholder="Tanggal Awal"
              />
              <b-input-group-append is-text>
                To
              </b-input-group-append>
              <flat-pickr
                v-model="date_end"
                class="form-control"
                placeholder="Tanggal Akhir"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- <b-col cols="12">
          <b-form-group
            label-cols-md="2"
            label="Status"
          >
            <b-form-select
              v-model="status"
              :options="[
                { value: null, text: 'Semua Status' },
                { value: 'bapp_requested', text: 'Requested' },
                { value: 'bapp_published', text: 'Published' },
              ]"
            />
          </b-form-group>
        </b-col> -->
        <b-col cols="12">
          <b-form-group
            label-cols-md="2"
            label=""
          >
            <b-button
              class="mr-1"
              size="sm"
              variant="primary"
              type="button"
              @click="show"
            >Show</b-button>
            <b-button
              class="mr-1"
              size="sm"
              variant="outline-secondary"
              type="button"
              @click="reset"
            >Reset</b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <basetable
        ref="basetable"
        :dataurl="dataurl+'?status=received,bapp_published,bapp_requested&filter[spbs.vendor_id]='+user.id"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :tombol_hide="true"
        :is-delete="isDelete"
        :is-edit="false"
        :is-detail="true"
        :filter="filter"
        :custom_filter="true"
      >
        <template v-slot:buttonaddon>
          <b-button
            class="float-right"
            variant="info"
            @click="showFilter =!showFilter"
          >
            <feather-icon
              icon="FilterIcon"
            />
            Filter
          </b-button>
        </template>
        <template #cell(status)="data">
        <center><span class="badge rounded-pill bg-info" v-if="data.item.status == 'created'">Pembuatan SPB</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'prepared'">Penyiapan Unit Oleh Vendor</span></center>
        <center><span class="badge rounded-pill bg-info" v-if="data.item.status == 'applied'">Pengajuan SPB</span></center>
        <center><span class="badge rounded-pill bg-info" v-if="data.item.status == 'delivered'">Pengiriman SPB Ke Vendor</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'viewed'">SPB Ditinjau</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'approved'">SPB Ditinjau</span></center>
        <center><span class="badge rounded-pill bg-warning" v-if="data.item.status == 'canceled'">Dibatalkan</span></center>
        <center><span class="badge rounded-pill bg-warning" v-if="data.item.status == 'returned'">SPB Direvisi</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'received'">Pengiriman Unit</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'distributed'">Pengiriman Unit dari Vendor</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'request_rikmatek'">Pengajuan Rikmatek</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'checked'">Sudah Diperiksa</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'send_letter_distribute'">Sudah Pemeriksaan</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'bapp_published'">BAPP Dipublish</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'bapp_requested'">BAPP Direquest</span></center>
        </template>
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV4.vue'
import {BCard, BButton, BCol, BFormSelect, BFormGroup, BRow, BInputGroup, BInputGroupAppend} from 'bootstrap-vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    basetable,
    BCard,
    BCol,
    BRow,
    BButton,
    FormVSelect,
    BFormSelect,
    BFormGroup,
    flatPickr,
    BInputGroup,
    BInputGroupAppend
  },
  data() {
    return {
      userType:null,
      user:null,
      title:this.$route.meta.pageTitle,
      isDelete:false,

      dataurl:"/spb",
      baseroute:'serah-terima-vendor',
      fields:[
        { key: 'number', label: 'SPB', sortable: true},
        { key: 'contract_subject', label: 'Judul Kontrak', sortable: true},
        { key: 'contract_number', label: 'Nomor Kontrak', sortable: true},
        { key: 'shipping_date', label: 'Tanggal Pengiriman', sortable: true},
        { key: 'status', label: 'Status', sortable: true},
      ],
      filter:[],
      showFilter : false,
      region:null,
      date_start:"",
      date_end:"",
      status:'received'
    }
  },
  created(){
    if(this.$store.state.profile.info){
      var info = this.$store.state.profile.info
      this.userType = info.contact_type.split('\\').pop().toLowerCase()
      this.user = this.$store.state.profile.info.contact
    }
  },
  methods:{
    show(){
      this.filter['filter[spbs.status]']=this.status
      this.filter['filter[spbs.branch_id]']=this.region?this.region.id:null
      if(this.date_start != "" && this.date_end != "")
        this.filter['between[spbs.shipping_date]']=this.date_start+","+this.date_end

      this.$refs.basetable.fetchData()
    },
    reset(){
      this.status = null
      this.region = null
      this.date_start = ""
      this.date_end = ""
      this.filter['between[spbs.shipping_date]'] = null
      this.show()
    }
  }
}
</script>